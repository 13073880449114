html {
  font-size: 62.5%;
}

body {
  font-family: $font-primary;
  position: relative;
}

ol,
ul {
  list-style: none;
}

button {
  outline: none;
  border: none;
  cursor: pointer;
  line-height: 1 !important;
  display: inline-block;
  background-color: transparent;
  font-size: inherit;
  margin: 0;
  padding: 0;
  font-weight: inherit;
  letter-spacing: inherit;
  color: inherit;
}

a[href],
a {
  text-decoration: none;
  line-height: 1;
  display: inline-block;
  color: inherit;
}

span,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  word-break: break-word;
}

object {
  pointer-events: none;
}
