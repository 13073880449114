.cards {
  position: relative;
  height: 430px;
  perspective: 1000px;
  transform-style: preserve-3d;
  margin: 0;
}

.cards label {
  position: absolute;
  width: 96%;
  left: 8px;
  margin: 0;
  cursor: pointer;
  transition: transform 0.55s ease;
}

#s1:checked ~ .cards #slide4,
#s2:checked ~ .cards #slide5,
#s3:checked ~ .cards #slide1,
#s4:checked ~ .cards #slide2,
#s5:checked ~ .cards #slide3 {
  transform: translate3d(-140%, 0, -720px);
  --current-color1: #eceaed;
  --current-color2: #404457;
}

#s1:checked ~ .cards #slide5,
#s2:checked ~ .cards #slide1,
#s3:checked ~ .cards #slide2,
#s4:checked ~ .cards #slide3,
#s5:checked ~ .cards #slide4 {
  transform: translate3d(-60%, 0, -320px);
  --current-color1: #eceaed;
  --current-color2: #404457;
}

#s1:checked ~ .cards #slide1,
#s2:checked ~ .cards #slide2,
#s3:checked ~ .cards #slide3,
#s4:checked ~ .cards #slide4,
#s5:checked ~ .cards #slide5 {
  transform: translate3d(0, 0, 0);
  --current-color1: #fad00c;
  --current-color2: #eceaed;
}

#s1:checked ~ .cards #slide2,
#s2:checked ~ .cards #slide3,
#s3:checked ~ .cards #slide4,
#s4:checked ~ .cards #slide5,
#s5:checked ~ .cards #slide1 {
  transform: translate3d(60%, 0, -320px);
  --current-color1: #eceaed;
  --current-color2: #404457;
}

#s1:checked ~ .cards #slide3,
#s2:checked ~ .cards #slide4,
#s3:checked ~ .cards #slide5,
#s4:checked ~ .cards #slide1,
#s5:checked ~ .cards #slide2 {
  transform: translate3d(140%, 0, -720px);
  --current-color1: #eceaed;
  --current-color2: #404457;
}
