.d-none {
  display: none;
}

.text-white {
  color: $color-white;
}

.min-height {
  min-height: 100vh;
  overflow: auto;
}

.hide-overflow {
  overflow: hidden;
}
