.card {
  position: relative;
  height: 100%;
  background-color: #323444;
  border-radius: 10px;
  border: 1px solid #ebebeb;
}

.imageContainer {
  border-radius: 5px;
  box-shadow: 15px 15px 40px rgba(0, 0, 0, 50%);
  width: 100%;
  height: 100%;
  object-fit: cover;

  .cardImage {
    border-radius: 5px;
    max-width: 100%;
    max-height: 100%;
  }
}

.cardHeading {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  display: none;
  bottom: 4px;
  height: 28px;
  width: 100%;
  font-size: 16px;
  border-radius: 0 0 5px 5px;
  justify-content: center;
  align-items: center;
}

.activeCard {
  display: flex;
}
