// mixin for font-styling
@mixin font-face($size: 1.6rem, $height: 1, $weight: 400, $spacing: 0) {
  font-size: $size;
  line-height: $height;
  font-weight: $weight;
  letter-spacing: $spacing;
}

// mixin for responsive queries
@mixin responsive($mq-breakpoint, $mq-breakpoints: $breakpoints) {
  @if map-has-key($breakpoints, $mq-breakpoint) {
    $mq-breakpoint: map-get($mq-breakpoints, $mq-breakpoint);
  }
  @media (min-width: #{$mq-breakpoint}) {
    @content;
  }
}

// mixin for flex-container
@mixin flex(
  $direction: row,
  $align: flex-start,
  $justify: flex-start,
  $wrap: nowrap
) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
  flex-wrap: $wrap;
}

// mixin for line-clamping
@mixin limitlines($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

// mixin for translating element up on hover
@mixin hover-effect {
  transition: all 0.3s;
  &:hover,
  &:focus,
  &:active {
    transform: translateY(-0.3rem);
  }
}

// mixin for changing on hover
@mixin hover-opacity {
  opacity: 0.6;
  &:hover,
  &:focus,
  &:active {
    opacity: 1;
  }
}

// mixin for changing color on hover
@mixin hover-color($color: $color-white) {
  &:hover,
  &:focus,
  &:active {
    color: $color;
  }
}
