.cardSlider {
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  padding-top: 30px;
  margin: 0;
}

.container {
  width: 96%;
  height: 100%;
  max-width: 600px;
  max-height: 600px;
  flex-direction: column;
  margin: 0;
}

.sliderButtonsWrapper {
  margin-top: 60px;
}

.sliderButton {
  margin: 0 10px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(130, 130, 130, 0.3);
}

.active {
  width: 28px;
  border-radius: 50px;
  background-color: #b4d9f8;
}

.buttonsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigationIcons {
  display: flex;
  cursor: pointer;
  color: rgba(130, 130, 130, 0.3);
  font-size: 24px;
}
